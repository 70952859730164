<template>
  <div>
    <!-- <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,0)">
      导出试卷
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,1,0)">
      导出试卷且显示题目来源
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,3)">
      导出答案解析
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,0,1)">
      导出完整版
    </div>
    <div class="text_btn_primary1"
         @click="printWord($route.query.edu_paper_id,1,1)">
      导出完整版且显示题目来源
    </div> -->
    <!-- 
    <span style="padding:0 20px 0 10px">
      <el-checkbox v-model="exportInfo.showTopic"
                   :disabled="!exportInfo.detail">题目</el-checkbox>
      <el-checkbox v-model="exportInfo.show_source">题目来源</el-checkbox>
      <el-checkbox v-model="exportInfo.detail"
                   @change="changeAnswer">答案解析</el-checkbox>
    </span> -->

    <div class="text_btn_primary1"
         @click="printWord">
      导出
    </div>
    <div class="text_btn_primary1"
         @click="printExp">
      导出解析
    </div>
    <!-- <div class="text_btn_primary1"
         @click="printCard">
      导出答题卡
    </div> -->
  </div>
</template>

<script>
import { saveAs } from "file-saver"
import { printPdf, exportExplain, exportSheet } from '@/api/dataAnalysis.js'
export default {
  data () {
    return {
      is_examine: 0, //是否显示考查细目表
      exportInfo: {
        detail: 0,
        show_source: 0,
        showTopic: true
      }
    }
  },
  props: {
    // 接收题目，在使用组件的地方去循环
    list: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    changeAnswer (value) {
      if (!this.exportInfo.detail) {
        this.exportInfo.showTopic = true
      }
    },
    // async printWord (edu_paper_id, show_source, detail, has_explain) {
    //   let params = {
    //     edu_paper_id: this.$route.query.edu_paper_id,
    //     detail: this.exportInfo.detail ? 1 : 0,
    //     show_source: this.exportInfo.show_source ? 1 : 0,
    //   }
    //   if (!this.exportInfo.showTopic) {
    //     params.detail = 3
    //   }
    //   // const data = await exportWord(edu_paper_id, detail, show_source, has_explain)
    //   const data = await exportWord(params)
    //   const a = document.createElement("a")
    //   a.href = window.URL.createObjectURL(data.data)
    //   a.download = `${this.$route.query.edu_paper_id}.docx`
    //   a.click()
    // },

    async printWord () {
      const data = await printPdf({ user_paper_id: this.list.user_paper_id })
      let blob = new Blob([data], { type: 'application/json;charset=utf-8' });
      saveAs(blob, `用户试卷.pdf`)
    },
    async printExp () {
      const data = await printPdf({ user_paper_id: this.list.user_paper_id, detail: 1 })
      let blob = new Blob([data], { type: 'application/json;charset=utf-8' });
      saveAs(blob, `${this.list.user_paper_id}_附解析.pdf`)

    },
    async printCard () {
      const { data } = await exportSheet({
        user_paper_id: this.list.user_paper_id,
      })
      let url = data.PDFUrl + '?response-content-type=application/octet-stream'
      window.open(url)

    },
  },
  mounted () {
    let user = window.localStorage.getItem("userInfo")
    if (user) {
      this.is_examine = JSON.parse(user).is_examine
    }
  }
}
</script>

<style scoped>
</style>