<template >
  <div  style="padding-bottom:30px; font-family: Regular;">
    <el-form label-width="90px">
      <el-row :gutter="4">
        <el-col :span="4">
          <el-form-item label="试卷ID：">
            {{list.user_paper_id}}
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷名称：">
            {{this.template_name }}
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷科目：">
            {{this.subject_name }}
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷来源：">
            {{list.question[0].source_name}}
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="试卷总分：">
            {{list.paper_score}}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="handle_btn_wrap">
      <div class="export_btn">
        <paperBtn :list="list" />
      </div>
    </div>
    <div v-if="list.note"
         style="margin:14px ">
      备注：{{list.note}}
    </div>
    <!--showOpertion 是否显示换题按钮  -->
    <div class="wrappp">
      <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                       :list="list.question"
                       :showOpertion="true"
                       :paper_enable="list.paper_enable"
                       :could_change_serial="list.could_change_serial"
                       :istest="true" />
    </div>
    <ChangeDialog ref="DialogRef" />

    <sortDialog ref="sortDialogRef" />
    <!-- <div class="btn"
         v-if="list.paper_enable != 1">
      <el-button type="primary"
                 @click="submitAll">提交试卷</el-button>
    </div> -->

    <scrollTo ref="scrollToRef"
              :list="childredList" />
    <jiucuo ref="jiucuoRef" />

  </div>
</template>

<script>
import tipicBody from '@/components/topic/index.vue'
import ChangeDialog from '@/components/changeTopic/index.vue'
import sortDialog from '../../../../components/changeTopic/sort.vue'
import scrollTo from '@/components/scrollTo'
import jiucuo from '@/components/jiucuo.vue'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'
import { upload } from '@/api/upload.js'
import html2canvas from 'html2canvas'
// import { instance } from '@/utils/twoHttp.js'
import PaperBtn from './exportPaperBtn.vue'
export default {
  data () {
    return {
      record_edu_paper: {},
      list: [],
      isActive: 0,
      could_change_serial: false,
      childredList: 0,
      type: '',
      selectForm: {
        has_explain: 0,
        show_source: 0
      },
      template_name:'',
      subject_name:'',

    }
  },
  components: {
    tipicBody, ChangeDialog, sortDialog, scrollTo, jiucuo, BASETOPICCOMMON, PaperBtn
  },
  mounted () {
    this.type = this.$route.query.type
    this.template_name = window.localStorage.getItem('template_name')
    this.subject_name = window.localStorage.getItem('subject_name')
    // document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'
    this.getInfo()
  },
  methods: {
    jiucuoTi (row) {
      let form = {
        question_id: row.question_id,
        comment: ''
      }
      this.$refs.jiucuoRef.form = form
      this.$refs.jiucuoRef.dialogVisible = true
    },
    getInfo () {
      console.log('sfasdgadsbasg', this.$route.query.user_paper_id)
      this.$http({
        url: '/api/v1/tang/paper_detail',
        method: 'get',
        params: {
          user_paper_id: this.$route.query.user_paper_id
        }
      }).then(res => {
        console.log('res', res.data.list)
        this.record_edu_paper = res.data.record_edu_paper
        this.list = res.data.list
        this.could_change_serial = res.data.could_change_serial
        setTimeout(() => {
          var oFixed = document.getElementsByClassName('yuan')
          this.childredList = oFixed.length
        }, 1000);
      })
    },
    // check (item) {
    //   // 这里逻辑需要等处理一下   传给后端的 to_replace_serial_in_paper 字段
    //   let form = {
    //     rule_id: item.rule_id,
    //     edu_paper_id: this.$route.query.edu_paper_id,
    //     serial_in_paper: item.answer_id,
    //     question_id: item.question_id,
    //     number_per_page: 1
    //   }
    //   if (item.question_children) {
    //     let arr = []
    //     item.question_children.forEach(ele => {
    //       arr.push(ele.answer_id)
    //     });
    //     form.serial_in_paper = arr.join(',')
    //     this.$refs.DialogRef.isHead = true
    //   } else {
    //     this.$refs.DialogRef.isHead = false
    //   }
    //   this.$refs.DialogRef.form = form
    //   this.$refs.DialogRef.dialogVisible = true
    // },
    submitAll () {
      switch (this.type) {
        case 'auto':
          this.submitAuto()
          break;
        case 'testing':
          this.submitTest()
          break;
        case 'knowledge':
          this.submitAuto()
          break;
        default:
          this.submitAuto()
          break;
      }

    },
    submitTest () {
      this.$http({
        url: '/api/v1/combination/replace_submit',
        method: 'post',
        data: {
          edu_paper_id: this.recordEduPaper.edu_paper_id
        }
      }).then(res => {
        this.back()
      })
    },
    submitAuto () {
      this.$http({
        url: '/api/v1/combination/test_paper_submit',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.back()
      })
    },

    back () {
      this.$notify({
        title: '提示',
        message: '提交试卷成功!',
        type: 'success'
      });
      this.$router.go(-1)
    },
    sort (item) {
      let form = JSON.parse(JSON.stringify(item))
      form.new_serial = item.serial_in_paper
      form.serial_in_paper = item.answer_id
      this.$refs.sortDialogRef.form = form
      this.$refs.sortDialogRef.sort()
      this.$refs.sortDialogRef.dialogVisible = true
    },
    async exportImg () {
      window.pageYoffset = 0;//网页位置
      document.documentElement.scrollTop = 0;//滚动条的位置
      document.body.scrollTop = 0;//网页被卷去的高
      let imgs = document.getElementsByTagName('img')
      let strs = ''

      let imagesList = []

      for (var index = 0; index < imgs.length; index++) {
        let str = imgs[index].getAttribute("src")
        if (str.includes('data:image')) continue
        imagesList.push(imgs[index])
        strs += str
        if (index != imgs.length - 1) {
          strs += ',a,'
        }
      }
      strs = strs.trim(',a,')

      const { data } = await instance({
        url: '/imgurl2base64',
        method: 'post',
        data: {
          imgurls: strs
        }
      })
      for (var index = 0; index < imagesList.length; index++) {
        imagesList[index].setAttribute("src", data[index])
        imagesList[index].removeAttribute("width")
        imagesList[index].removeAttribute("height")
      }

      setTimeout(async () => {
        let canvas = await html2canvas(document.querySelector(".wrappp"), {
          // allowTaint: true,  //开启跨域
          // useCORS: true,
        });
        let base64Img = canvas.toDataURL();//将canvas转为base64
        let formdata = new FormData();
        formdata.append("file", this.toImgStyle(base64Img, Date.now() + '.png'));//此处参数一字段为后端要求，参数二后端要求传递形式为png，所以此处又调用toImgStyle方法将base64转为png格式
        formdata.append("filename", "file")
        let form = {
          filename: "file"
        }
        formdata.append('sign', this.$getMd5Form(form))
        await upload(formdata)
      }, 500)
    },
    toImgStyle (base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
        bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
        len = bstr.length,
        u8arr = new Uint8Array(len); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      };
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      })
    },


  }
}
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
  margin-right: 10px;
  color: #409eff;
}
.check {
  cursor: pointer;
  color: #409eff;
}
.btn {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
::v-deep .el-loading-mask {
  z-index: 2010 !important;
}

.tipicBody_class {
  margin-top: 20px;
  position: relaive;
  margin-right: 100px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.paper_warp {
}
::v-deep img {
  vertical-align: middle;
}
::v-deep p {
  margin-bottom: 5px;
}
.handle_btn_wrap {
  display: flex;
  width: calc(100% - 150px);
  align-items: flex-end;
  .export_btn {
    flex: 1;
  }
  .paper_collect_btn {
    // width: 90px;
    background: #409eff;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 6px 15px;
    cursor: pointer;
    &:hover {
      background: #68bcff;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
.info_export {
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }
}
</style>